/* Customizations to the designer theme should be defined here */
.floated-label {
  &:not(.floated-active) {
    color: var(--input-placeholder-color);
    font-size: 14px;
    padding-left: 0;
    pointer-events: none;
    &.isTextarea {
      padding-left: 5px;
    }
  }
  
  &.floated-active.isTextarea {
    top: -3px;
  }
}
